<template>
  <div id="tipos" class="tiposAdecuaciones mt-5 pb-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h1 class="font-nuni-bo font-color-blueblack font-size-42 text-center py-3 px-0 px-sm-5">Tipos de adecuaciones
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
       
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Sanitarios</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en sanitarios.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-sanitario.png" title="transformación de contenedores a sanitarios" alt="imagen de contenedor sanitario" description="imagen de contenedor sanitario finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Bodegas refrigeradas</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados para bodegas refrigeradas.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-bodega-refrigerada.png" title="transformación de contenedores a bodegas" alt="imagen de contenedor bodega" description="imagen de contenedor bodega finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Comedores</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en comedores.</p>
                 <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-comedor.png" title="transformación de contenedores a comedores" alt="imagen de contenedor comedor" description="imagen de contenedor comedor finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Piscinas</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en piscinas.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-piscina.png" title="transformación de contenedores a piscinas" alt="imagen de contenedor piscina" description="imagen de contenedor piscina finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Comercios</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en comercios.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-comercio.png" title="transformación de contenedores a comercios" alt="imagen de contenedor comercio" description="imagen de contenedor comercio finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Fast Food</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en negocios de alimentos.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-negocio-alimentos.png" title="transformación de contenedores a fastfood" alt="imagen de contenedor fastfood" description="imagen de contenedor fastfood finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Oficinas</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en oficinas.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-oficinas.png" title="transformación de contenedores a oficinas" alt="imagen de contenedor oficina" description="imagen de contenedor oficina finalizado en uso">
                </div>
              </div>           
        </div>
         <div class="col-12 col-md-6 pt-4 mt-1">         
              <div class="d-flex flex-column flex-md-row border-color-blue p-4">
                <div class="d-flex flex-column order-2 order-md-1 justify-content-between pl-0 pr-2 width-50 width-md-100">
                  <h2 class="font-nuni-bo font-size-20 font-color-blueblack mt-4 mt-md-0 mb-4 text-left">Dormitorios</h2>
                  <p class="font-koho-re font-size-14 font-color-brown text-left mb-4">Vendemos contenedores marítimos
                    adecuados en dormitorios.</p>
                  <a title="Ir a cotizar" alt="Ir a la sección de cotización de contenedores" href="/cotizacion"
                    class="button-back-blue button-size-100 font-koho-me font-size-14 py-3 mt-3 d-block">Quiero
                    comprar</a>
                </div>
                <div class=" d-flex justify-content-center order-1 order-md-2 align-items-center px-0 width-50 width-md-100">
                  <img class="img-fluid w-100" src="@/assets/img/adecuaciones/contenedor-dormitorios.png" title="transformación de contenedores a dormitorios" alt="imagen de contenedor dormitorio" description="imagen de contenedor dormitorio finalizado en uso">
                </div>
              </div>           
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tiposAdecuaciones',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>