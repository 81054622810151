<template>
  <div class="localizacion">
    <Header />
    <Nav />
    <DatosLocalizacion />
    <OficinaCentral />
    <Patios />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import DatosLocalizacion from '@/components/DatosLocalizacion.vue'
import OficinaCentral from '@/components/OficinaCentral.vue'
import Patios from '@/components/Patios.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Localizacion',
  components: {
    Header,
    Nav,
    DatosLocalizacion,
    OficinaCentral,
    Patios,
    Footer
  }
}
</script>