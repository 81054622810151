<template>
  <div class="oficinaCentral">
    <div class="container mb-5">
      <div class="row">
        <div class="col-12 col-md-6 back-color-blueblack px-0 d-flex align-items-center">
          <div class="font-color-white p-lg-5 p-md-2 p-3  text-left">
            <h2 class="font-nuni-bo font-size-18 mb-4">
              Oficina central
            </h2>
            <table class="table table-invisible-style font-koho-me font-size-14 font-color-white">
              <tbody>
                <tr>
                  <td>Ciudad</td>
                  <td>Ciudad de México</td>
                </tr>
                <tr>
                  <td>Dirección</td>
                  <td>Av. del Árbol 104, Col. Lomas de San Lorenzo. C.P. 09780 Alcaldía Iztapalapa, CDMX.</td>
                </tr>
                <tr>
                  <td>Teléfono</td>
                  <td>
                    <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" href="tel:5526088867" class="ancor-style-generic font-color-white p-1">55 26 08 88 67</a><br>
                    <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" href="tel:5554269941"
                      class="ancor-style-generic font-color-white p-1 my-2 d-inline-block">&nbsp;55 54 26 99 41</a>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td><a title="Mandar un correo" alt="Mandar un correo a creativos espacios" href="mailto:ventas@creativosespacios.mx"
                      class="ancor-style-generic font-color-white p-1">ventas@creativosespacios.mx</a></td>
                </tr>
              </tbody>
            </table>           
          </div>
        </div>
        <div class="col-12 col-md-6 px-0">
          <div class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30121.007819187384!2d-99.056176!3d19.320339!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcf94e14da72b42e9!2sVenta%2C%20renta%20y%20adaptaci%C3%B3n%20de%20contenedores%20mar%C3%ADtimos%20Creativos%20Espacios.!5e0!3m2!1ses-419!2smx!4v1618885836288!5m2!1ses-419!2smx"
              style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OficinaCentral',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>