<template>
  <div id="contenedores-maritimos" class="contenedores  mt-5 pb-5">

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-nuni-bo font-color-blueblack font-size-42 text-center py-3 px-0 px-sm-5">Tipos de contenedores
          </h1>
          <p class="font-size-14 font-koho-me text-left px-md-5 px-0">
            Contamos con contenedores nuevos, de un sólo uso y/o usados para más información contáctanos o visítanos, te
            explicaremos con mayor detalle la información y disponibilidad.
          </p>
        </div>
      </div>
    </div>

    <div class="container ">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Dry o seco, son los más comunes. Su contrucción es básica, metálicos, cerrados herméticamente." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">Dry</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-10pies.png" title="contenedor maritimo 10 pies" alt="imagen de contenedor maritimo 10 pies" description="imagen de contenedor maritimo 10 pies de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo seco<br
                    class="d-none d-lg-block"> de 10 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125" id="seco10pies">
                  Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Dry o seco, son los más comunes. Su contrucción es básica, metálicos, cerrados herméticamente." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">Dry</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-20pies.png" title="contenedor maritimo 20 pies" alt="imagen de contenedor maritimo 20 pies" description="contenedor maritimo 20 pies de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo seco<br
                    class="d-none d-lg-block"> de 20 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125" id="seco20pies">
                  Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="STD o estándar, son los más comunes. Su contrucción es básica, metálicos, cerrados herméticamente." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">STD</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedore-maritimo-STD-40pies.png" title="contenedor maritimo STD 40 pies" alt="imagen de contenedor maritimo STD 40 pies" description="contenedor maritimo STD 40 pies de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo STD<br
                    class="d-none d-lg-block"> de 40 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125" id="STD40pies">
                  Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="High Cube. Su contrucción es básica, metálicos, cerrados herméticamente. Aumenta la capacidad cúbica interna del 13% con respecto a el contenedor seco o dry." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">HC</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-HC-40pies.png" title="contenedor maritimo HC 40 pies" alt="imagen de contenedor maritimo HC 40 pies" description="imagen de contenedor maritimo HC 40 pies de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo HC <br
                    class="d-none d-lg-block">de 40 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125" id="HC40pies">
                  Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-bluelight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Reefer o refrigerado. Proporcionan un temperatura controlada, conectada a fuentes de energía, logra temperaturas de  -18° hasta 30°." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">RF</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-refrigerado-20pies.png" title="contenedor maritimo refrigerado 20 pies" alt="imagen de contenedor maritimo refrigerado 20 pies" description="imagen de contenedor maritimo refrigerado 20 pies de color azul">
            </div>
            <div class="back-color-grayrefeer py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo
                  <br>refrigerado de 20 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="refrigerado20pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-bluelight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Reefer o refrigerado. Proporcionan un temperatura controlada, conectada a fuentes de energía, logra temperaturas de  -18° hasta 30°." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">RF</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-refrigerado-40pies.png" title="contenedor maritimo refrigerado 40 pies" alt="imagen de contenedor maritimo refrigerado 40 pies" description="imagen de contenedor maritimo refrigerado 40 pies de color azul">
            </div>
            <div class="back-color-grayrefeer py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo
                  <br>refrigerado de 40 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="refrigerado40pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Open Top o abiertos por arriba. Tienen un techo removible de lona." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">OT</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-opentop-20pies.png" title="contenedor maritimo opentop 20 pies" alt="imagen de contenedor maritimo opentop 20 pies" description="imagen de contenedor maritimo opentop 20 pies de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo <br>open top
                  de 20 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="opentop20pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Open Top o abiertos por arriba. Tienen un techo removible de lona." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">OT</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-opentop-40pies.png" title="contenedor maritimo opentop 40 pies" alt="imagen de contenedor maritimo opentop 40 pies" description="imagen de contenedor maritimo opentop 40 pies de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo <br>open top
                  de 40 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="opentop40pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Double Door o con doble puerta. Cuentan con puertas en ambas laterales frontales." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">DO</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-doubledoor.png" title="contenedor maritimo doubledoor" alt="imagen de contenedor maritimo doubledoor" description="imagen de contenedor maritimo doubledoor de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo <br>double
                  door de 20 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="doubledoor20pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Double Door o con doble puerta. Cuentan con puertas en ambas laterales frontales." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">DO</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-doubledoor.png" title="contenedor maritimo doubledoor" alt="imagen de contenedor maritimo doubledoor" description="imagen de contenedor maritimo doubledoor de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo <br>double
                  door de 40 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="doubledoor40pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Open side o abierta por la parte lateral. Cuentan con una abertura lateral." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">OS</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-openside.png" title="" alt="imagen de contenedor maritimo openside" description="imagen de contenedor maritimo openside de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo <br>open side
                  de 20 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="openside20pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-5 pt-5">
          <div class="mx-0 mx-lg-3">
            <div class="back-color-yellowlight">
              <div class="contenent-squares-brown">
                <div v-b-tooltip.hover title="Open side o abierta por la parte lateral. Cuentan con una abertura lateral." class="square-brown-text d-flex justify-content-center align-items-center"><span
                    class="font-color-white font-size-24 font-nuni-re line-heigt-1">OS</span></div>
                <div class="square-brown-text"></div>
                <div class="square-brown-text"></div>
              </div>

              <img class="img-fluid" src="@/assets/img/tipos/contenedor-maritimo-openside.png" title="contenedor maritimo openside" alt="imagen de contenedor maritimo openside" description="imagen de contenedor maritimo openside de color azul">
            </div>
            <div class="back-color-blueblack py-4 px-3">
              <div>
                <h2 class="font-size-18 font-color-white font-nuni-re text-left mb-0">Contenedor marítimo <br>open side
                  de 40 pies
                </h2>
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-around w-100 ">
                <b-button class="button-out-white font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  id="openside40pies">Datos
                  técnicos</b-button>
                <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-yellow font-koho-me font-size-14 py-2 mt-3  button-size-125"
                  href="/cotizacion">Comprar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-popover target="seco10pies" triggers="hover" placement="top">
      <p>
       Dry - Van 10' ST
      </p>
      <table class="table table-bordered">       
        <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>3.029mm</td>
            <td><strong>Largo interno</strong><br>2.949mm</td>
            <td><strong>Capacidad</strong><br>16,40m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>1,145kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>15,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="seco20pies" triggers="hover" placement="top">
       <p>
       Dry - Van 20' ST
      </p>
      <table class="table table-bordered">
        <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>6.058mm</td>
            <td><strong>Largo interno</strong><br>5.900mm</td>
            <td><strong>Capacidad</strong><br>33,30m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>2,250kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>28,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="STD40pies" triggers="hover" placement="top">
     <p>
       Dry - Van 40' ST
      </p>
      <table class="table table-bordered">
       <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>12.192mm</td>
            <td><strong>Largo interno</strong><br>12.030mm</td>
            <td><strong>Capacidad</strong><br>67,70m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>3,630kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>28,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="HC40pies" triggers="hover" placement="top">
      <p>
       Dry - Van 40' ST High Cube
      </p>
      <table class="table table-bordered">
          <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>12.192mm</td>
            <td><strong>Largo interno</strong><br>12.030mm</td>
            <td><strong>Capacidad</strong><br>76,50m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.3450mm</td>
            <td><strong>Peso vacío</strong><br>3,800kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.896mm</td>
            <td><strong>Altura interna</strong><br>2.710mm</td>
            <td><strong>Máximo peso</strong><br>28,850kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="refrigerado20pies" triggers="hover" placement="top">
    <p>
       Reefer - Van 20' ST 
      </p>
      <table class="table table-bordered">
          <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>6.058mm</td>
            <td><strong>Largo interno</strong><br>5.500mm</td>
            <td><strong>Capacidad</strong><br>28,30m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.285mm</td>
            <td><strong>Peso vacío</strong><br>3,400kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.255mm</td>
            <td><strong>Máximo peso</strong><br>27,280kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="refrigerado40pies" triggers="hover" placement="top">
      <p>
       Reefer - Van 40' ST 
      </p>
      <table class="table table-bordered">
          <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>12.192mm</td>
            <td><strong>Largo interno</strong><br>11.575mm</td>
            <td><strong>Capacidad</strong><br>58,70m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.285mm</td>
            <td><strong>Peso vacío</strong><br>4,500kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.250mm</td>
            <td><strong>Máximo peso</strong><br>30,400kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="opentop20pies" triggers="hover" placement="top">
      <p>
       Open Top -  20' ST
      </p>
     <table class="table table-bordered">
        <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>6.058mm</td>
            <td><strong>Largo interno</strong><br>5.900mm</td>
            <td><strong>Capacidad</strong><br>33,30m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>2,250kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>28,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="opentop40pies" triggers="hover" placement="top">
      <p>
       Open Top -  40' ST
      </p>
      <table class="table table-bordered">
       <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>12.192mm</td>
            <td><strong>Largo interno</strong><br>12.030mm</td>
            <td><strong>Capacidad</strong><br>67,70m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>3,630kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>28,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="doubledoor20pies" triggers="hover" placement="top">
      <p>
      Double Door - 20' ST
      </p>
      <table class="table table-bordered">
        <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>6.058mm</td>
            <td><strong>Largo interno</strong><br>5.900mm</td>
            <td><strong>Capacidad</strong><br>33,30m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>2,250kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>28,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="doubledoor40pies" triggers="hover" placement="top">
      <p>
      Double Door - 40' ST
      </p>
     <table class="table table-bordered">
       <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>12.192mm</td>
            <td><strong>Largo interno</strong><br>12.030mm</td>
            <td><strong>Capacidad</strong><br>67,70m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>3,630kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>26,850kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="openside20pies" triggers="hover" placement="top">
      <p>
      Open Side - 20' ST
      </p>
     <table class="table table-bordered">
        <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>6.058mm</td>
            <td><strong>Largo interno</strong><br>5.900mm</td>
            <td><strong>Capacidad</strong><br>33,30m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>2,250kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>28,240kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

    <b-popover target="openside40pies" triggers="hover" placement="top">
     <p>
       Open Side - 40' ST
      </p>
     <table class="table table-bordered">
       <tbody>
          <tr>           
            <td><strong>Largo externo</strong><br>12.192mm</td>
            <td><strong>Largo interno</strong><br>12.030mm</td>
            <td><strong>Capacidad</strong><br>67,70m<sup>3</sup></td>            
          </tr>
          <tr>            
            <td><strong>Ancho externo</strong><br>2.438mm</td>
            <td><strong>Ancho interno</strong><br>2.345mm</td>
            <td><strong>Peso vacío</strong><br>3,630kg</td>   
          </tr>
          <tr>            
            <td><strong>Altura externa</strong><br>2.591mm</td>
            <td><strong>Altura interna</strong><br>2.400mm</td>
            <td><strong>Máximo peso</strong><br>26,850kg</td>
          </tr>
        </tbody>
      </table>
    </b-popover>

  </div>
</template>

<script>
export default {
  name: 'Contenedores',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after{
  border-bottom-color:$color-metal-black!important;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after{
  border-top-color: $color-metal-black!important;
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before{
  border-top-color: $color-metal-black!important;
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after{
  border-top-color: $color-metal-black!important;
}

.popover{
  max-width: max-content;
  border-radius: 0;
  border: none;
    background: $color-metal-black;
  .popover-body{
    padding: 1rem;
    p{
      font-family: $font-koho-regular;
      color: $color-white-principal;
      font-size: 18px;
    }    
    table{
      font-family: $font-koho-regular;
      color: $color-white-principal;
    }

  }
}
</style>