<template>
  <div class="transformacion">
    <Header />
    <Nav/>
    <div id="modificacion-de-contenedores" class="servicio-modificacion mt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="back-color-blue position-relative">
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="row">
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0">
                    <img class="img-fluid image-servicios-zoom"
                      src="@/assets/img/inicio/modificacion-contenedores-maritimos.png" title="transformación de contenedores marítimos" alt="imagen de transformación de contenedores marítimos" description="imagen de transformación de contenedores marítimos café">
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-column align-items-start justify-content-center pr-lg-5">
                    <h2 class="font-size-24 font-color-brown font-nuni-bo text-left pt-3 pt-lg-0 px-3 pl-lg-0">Modificación de contenedores<br class="d-none d-lg-block"> maritimos
                    </h2>
                    <p class="font-size-14 font-color-brown font-koho-me text-left px-3 pl-lg-0 mt-3">Transforma tu contenedor marítimo te ayudamos si estas en cero o si eres todo un experto. No olvides que requieres las medidas de tu terreno, servicios básicos (luz, agua, etc) y muchas ganas de alcanzar tus sueños.</p>
                    <div class="d-flex justify-content-around w-100 pb-5 pb-lg-0">
                      <a title="Ir a cotizar" alt="Ir a la sección de cotización" class="button-back-brown font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/cotizacion">Quiero
                        una cotización</a>
                        <a title="Ir a tipos de contenedores" alt="Ir a la sección de tipos" class="button-back-brown font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/adecuaciones">Saber más</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideosAdecuaciones />
    <DatosLocalizacion/>
    <OficinaCentral/>
    <TiposAdecuaciones/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import VideosAdecuaciones from '../components/VideosAdecuaciones.vue'
import DatosLocalizacion from '@/components/DatosLocalizacion.vue'
import OficinaCentral from '@/components/OficinaCentral.vue'
import TiposAdecuaciones from '@/components/TiposAdecuaciones.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Transformacion',
  components: {
    Header,
    Nav,
    VideosAdecuaciones, 
    DatosLocalizacion,
    OficinaCentral,   
    TiposAdecuaciones,
    Footer,
    
}
}
</script>
