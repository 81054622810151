<template>
  <div id="formulario" class="formularioCotizacion">
    <div class="container-fluid">
      <div class="row no-gutters"> 
        <div class="col-12 col-md-6 col-lg-12 d-flex flex-column flex-md-row">
          <a href="#rentar" class="ancor-forms-style font-nuni-bo font-size-42 text-left py-2 px-4 mx-0 mx-md-4 d-flex text-center ">Rentar</a>
          <a href="#comprar" class="ancor-forms-style font-nuni-bo font-size-42 text-left py-2 px-4 mx-0 mx-md-4 d-flex text-center">Comprar</a>
          <a href="#modificar" class="ancor-forms-style font-nuni-bo font-size-42 text-left py-2 px-4 mx-0 mx-md-4 d-flex text-center">Modificar</a>
        </div>
      </div>
      <div class="row back-color-blue d-flex justify-content-center align-items-center p-0 p-xl-5 ">
        <div id="rentar" class="col-12 col-sm-11 col-md-9 m-5 ">
          <div class="row">
            <div class="col-12 col-md-4 d-flex flex-column back-color-bluemedium">
              <div class="p-0 py-3 p-lg-5 d-flex flex-column justify-content-between h-100">
                <div class="pb-5">
                  <h4 class="font-size-14 font-koho-me font-color-white text-left">Para rentar</h4>
                  <h3 class="font-size-24 font-nuni-bo font-color-white text-left">Cumplimos con toda la documentación para ofrecerte la renta</h3>
                </div>
                <div class="pb-5">
                  <img class="img-fluid" src="@/assets/img/cotizacion/img-rentail-keys.png" title="renta" alt="imagen de llaves de renta de contenedor" description="imagen de llaves de renta de contenedor de llaves">
                </div>
                <div class="pt-5">
                  <h4 class="font-size-14 font-koho-me font-color-white text-left">Rápido, seguro y satisfactorio</h4>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center align-items-center back-color-white py-5">
              <div class="border-color-blue p-2 p-md-4 width-md-100 width-lg-75 width-50">
                <form name="form" id="form" v-on:submit.prevent="procesarRenta();" class="text-left font-size-14 font-koho-me font-color-brown">
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso1">Paso 1</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso1">Tus datos (por lo menos tu correo o teléfono)</p>
                  <input class="form-control " type="mail" name="correoRentar" placeholder="Correo electrónico*"  v-model="contactoRentar.correo" required>
                  <input class="form-control " type="text" name="nombreRentar" placeholder="Escribe tu nombre" v-model="contactoRentar.nombre" required>
                  <input class="form-control " type="phone" name="telefonoRentar" placeholder="Escribe tu telefono" v-model="contactoRentar.telefono" required>
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso2 mt-4">Paso 2</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso2">Destino de entrega (por lo menos tu ciudad)</p>
                  <input class="form-control " type="text" name="calleRentar" placeholder="Escribe tu calle" v-model="contactoRentar.calle" required>
                  <input class="form-control " type="text" name="coloniaRentar" placeholder="Escribe tu colonia" v-model="contactoRentar.colonia" required>
                  <input class="form-control " type="text" name="delegacionRentar" placeholder="Escribe tu delegación o municipio" v-model="contactoRentar.delegacion" required>
                  <input class="form-control " type="text" name="estadoRentar" placeholder="Escribe tu estado o cuidad*" v-model="contactoRentar.estado" required>
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso3 mt-4">Paso 3</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso3">Datos para atenderte mejor</p>
                  <input class="form-control " type="text" name="tipoRentar" placeholder="Tipo de contenedor" v-model="contactoRentar.tipo" required>
                  <input class="form-control " type="text" name="mesesRentar" placeholder="¿Cuántos meses?" v-model="contactoRentar.meses" required>
                  <input class="form-control " type="text" name="contenedoresRentar" placeholder="¿Cuántos contenedores?" v-model="contactoRentar.contenedores" required>
                  <input class="form-control " type="text" name="comentariosRentar" placeholder="Añade comentarios adicionales" v-model="contactoRentar.comentarios" required>
                  <label class="form-check-label mt-4 text-center w-100" for="invalidCheck">Al dar clic en enviar aceptas los <br> <a title="ir a los términos y condiciones" alt="página de términos y condiciones de creativos espacios" class="ancor-style-generic font-color-brown p-1 " href="https://creativosespacios.mx/terminos"> términos y condiciones</a>
                  </label>
                  <button class="btn button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100 mt-5 mb-2 btn-primary" type="submit">Enviar</button>
                </form>
                <!-- <b-card class="mt-3" header="Form Data Result"><pre class="m-0">{{ form }}</pre></b-card>-->
              </div>
            </div>
          </div>
        </div>
        <div id="comprar" class="col-12 col-sm-11 col-md-9 m-5">
          <div class="row">
            <div class="col-12 col-md-4 d-flex flex-column back-color-bluemedium">
              <div class="p-0 py-3 p-lg-5 d-flex flex-column justify-content-between h-100">
                <div class="pb-5">
                  <h4 class="font-size-14 font-koho-me font-color-white text-left">Para comprar</h4>
                  <h3 class="font-size-24 font-nuni-bo font-color-white text-left">No esperes más, llévate el contenedor que tanto necesitas para tu proyecto</h3>
                </div>
                <div class="pb-5">
                  <img class="img-fluid" src="@/assets/img/cotizacion/img-pay-card.png" title="venta" alt="imagen de tarjeta de debito" description="imagen de tarjeta de debito azul">
                </div>
                <div class="pt-5">
                  <h4 class="font-size-14 font-koho-me font-color-white text-left">Te ayudamos siempre</h4>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center align-items-center back-color-white py-5">
              <div class="border-color-blue p-2 p-md-4 width-md-100 width-lg-75 width-50">
                <form name="form" id="form" v-on:submit.prevent="procesarCompra();" class="text-left font-size-14 font-koho-me font-color-brown">
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso1">Paso 1</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso1">Tus datos (por lo menos tu correo o teléfono)</p>
                  <input class="form-control " type="mail" name="correoComprar" placeholder="Correo electrónico*"  v-model="contactoComprar.correo" required>
                  <input class="form-control " type="text" name="nombreComprar" placeholder="Escribe tu nombre" v-model="contactoComprar.nombre" required>
                  <input class="form-control " type="phone" name="telefonoComprar" placeholder="Escribe tu telefono" v-model="contactoComprar.telefono" required>
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso2 mt-4">Paso 2</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso2">Destino de entrega (por lo menos tu ciudad)</p>
                  <input class="form-control " type="text" name="calleComprar" placeholder="Escribe tu calle" v-model="contactoComprar.calle" required>
                  <input class="form-control " type="text" name="coloniaComprar" placeholder="Escribe tu colonia" v-model="contactoComprar.colonia" required>
                  <input class="form-control " type="text" name="delegacionComprar" placeholder="Escribe tu delegación o municipio" v-model="contactoComprar.delegacion" required>
                  <input class="form-control " type="text" name="estadoComprar" placeholder="Escribe tu estado o cuidad*" v-model="contactoComprar.estado" required>
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso3 mt-4">Paso 3</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso3">Datos para atenderte mejor</p>
                  <input class="form-control " type="text" name="tipoComprar" placeholder="Tipo de contenedor" v-model="contactoComprar.tipo" required>
                  <input class="form-control " type="text" name="contenedoresComprar" placeholder="¿Cuántos contenedores?" v-model="contactoComprar.contenedores" required>
                  <input class="form-control " type="text" name="comentariosComprar" placeholder="Añade comentarios adicionales" v-model="contactoComprar.comentarios" required>
                  <label class="form-check-label mt-4 text-center w-100" for="invalidCheck">Al dar clic en enviar aceptas los <br> <a title="ir a los términos y condiciones" alt="página de términos y condiciones de creativos espacios" class="ancor-style-generic font-color-brown p-1 " href="https://creativosespacios.mx/terminos"> términos y condiciones</a>
                  </label>
                  <button class="btn button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100 mt-5 mb-2 btn-primary" type="submit">Enviar</button>
                </form>
                <!-- <b-card class="mt-3" header="Form Data Result"><pre class="m-0">{{ form }}</pre></b-card>-->
              </div>
            </div>
          </div>
        </div>
        <div id="modificar" class="col-12 col-sm-11 col-md-9 m-5">
          <div class="row">
            <div class="col-12 col-md-4 d-flex flex-column back-color-bluemedium">
              <div class="p-0 py-3 p-lg-5 d-flex flex-column justify-content-between h-100">
                <div class="pb-5">
                  <h4 class="font-size-14 font-koho-me font-color-white text-left">Para modificar</h4>
                  <h3 class="font-size-24 font-nuni-bo font-color-white text-left">Contamos con expertos para transformar tu sueño en realidad</h3>
                </div>
                <div class="pb-5">
                  <img class="img-fluid" src="@/assets/img/cotizacion/img-transform-plains.png" title="modificar" alt="imagen de planos arquitectonicos" description="imagen de planos arquitectonicos blanco">
                </div>
                <div class="pt-5">
                  <h4 class="font-size-14 font-koho-me font-color-white text-left">Lo podemos hacer todo por ti</h4>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 d-flex justify-content-center align-items-center back-color-white py-5">
              <div class="border-color-blue p-2 p-md-4 width-md-100 width-lg-75 width-50">
                <form name="form" id="form" v-on:submit.prevent="procesarModificar();" class="text-left font-size-14 font-koho-me font-color-brown">
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso1">Paso 1</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso1">Tus datos (por lo menos tu correo o teléfono) <br>
                  Recuerda que necesitaremos que cuentes con un terreno e indicar si tiene los servicios de agua, luz y drenaje</p>
                  <input class="form-control " type="mail" name="correoModificar" placeholder="Correo electrónico*"  v-model="contactoModificar.correo" required>
                  <input class="form-control " type="text" name="nombreModificar" placeholder="Escribe tu nombre" v-model="contactoModificar.nombre" required>
                  <input class="form-control " type="phone" name="telefonoModificar" placeholder="Escribe tu telefono" v-model="contactoModificar.telefono" required>
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso2 mt-4">Paso 2</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso2">Destino de entrega (por lo menos tu ciudad)</p>
                  <input class="form-control " type="text" name="calleModificar" placeholder="Escribe tu calle" v-model="contactoModificar.calle" required>
                  <input class="form-control " type="text" name="coloniaModificar" placeholder="Escribe tu colonia" v-model="contactoModificar.colonia" required>
                  <input class="form-control " type="text" name="delegacionModificar" placeholder="Escribe tu delegación o municipio" v-model="contactoModificar.delegacion" required>
                  <input class="form-control " type="text" name="estadoModificar" placeholder="Escribe tu estado o cuidad*" v-model="contactoModificar.estado" required>
                  <h5 class="font-size-18 font-koho-sb font-color-blue text-left paso3 mt-4">Paso 3</h5>
                  <p class="font-size-14 font-koho-bo font-color-brown text-left paso3">Datos para atenderte mejor</p>
                  <input class="form-control " type="text" name="presupuestoModificar" placeholder="Presupuesto aproximado en pesos mexicanos" v-model="contactoModificar.presupuesto" required>
                  <input class="form-control " type="text" name="construccionModificar" placeholder="Metros cuadrados de construcción" v-model="contactoModificar.construccion" required>
                  <input class="form-control " type="text" name="personasModificar" placeholder="No. de personas que lo utilizarán" v-model="contactoModificar.personas" required>
                  <input class="form-control " type="text" name="modificacionModificar" placeholder="¿Que tipo de adecuaciones te gustaría que hicieramos?" v-model="contactoModificar.modificacion" required>                                 
                  <label class="form-check-label mt-4 w-100">
                    Recuerda indicar en los comentario adicionales si cuentas con planos, renders, bocetos, diseño o imagenes.
                  </label>
                  <input class="form-control " type="text" name="comentarios" placeholder="Añade comentarios adicionales" v-model="contactoModificar.comentarios" required>
                  <label class="form-check-label mt-4 text-center w-100" for="invalidCheck">Al dar clic en enviar aceptas los <br> <a title="ir a los términos y condiciones" alt="página de términos y condiciones de creativos espacios" class="ancor-style-generic font-color-brown p-1 " href="https://creativosespacios.mx/terminos"> términos y condiciones</a>
                  </label>
                  <button class="btn button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100 mt-5 mb-2 btn-primary" type="submit">Enviar</button>
                </form>
                <!-- <b-card class="mt-3" header="Form Data Result"><pre class="m-0">{{ form }}</pre></b-card>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
	name: 'FormularioCotizacion',
	props: {
		msg: String
	},
	data() {
		return {
			contactoRentar: {
        correo: '',
				nombre: '',
        telefono: '',        
        calle: '',
        colonia: '',
        delegacion: '',
        estado: '',
        tipo: '',
        meses: '',
        contenedores: '',								
				comentarios: ''
			},
			contactoComprar: {
        correo: '',
				nombre: '',
        telefono: '',        
        calle: '',
        colonia: '',
        delegacion: '',
        estado: '',
        tipo: '',        
        contenedores: '',								
				comentarios: ''
			},
			contactoModificar: {
        correo: '',
				nombre: '',
        telefono: '',        
        calle: '',
        colonia: '',
        delegacion: '',
        estado: '',
        presupuesto: '',
        construccion: '',
        personas: '',
        modificacion: '',      						
				comentarios: ''
			}
		}
	},
	methods: {
		procesarCompra() {
			axios.post('comprar.php', this.contactoComprar)
				.then(function(response) {
					console.log(response);
					alert("mensaje enviado exitosamente");
				})
				.catch(function(error) {
					console.log(error);
				});
			console.log(this.contactoComprar);
		},
		procesarRenta() {
			axios.post('renta.php', this.contactoRentar)
				.then(function(response) {
					console.log(response);
					alert("mensaje enviado exitosamente");
				})
				.catch(function(error) {
					console.log(error);
				});
			console.log(this.contactoRentar);
		},
		procesarModificar() {
			axios.post('modificar.php', this.contactoModificar)
				.then(function(response) {
					console.log(response);
					alert("mensaje enviado exitosamente");
				})
				.catch(function(error) {
					console.log(error);
				});
			console.log(this.contactoModificar);
		}

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>