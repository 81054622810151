<template>
  <div class="cotizacion">
    <Header />
    <Nav />
    <PasosCotizacion/>
        <div class="pb-0 pb-md-5">
      <div class="container mt-5 mb-5">
        <div class="row">
          <div class="col-12 col-md-6">
            <img class="img-fluid w-100" src="@/assets/img/cotizacion/img-mercado-blue.jpg" title="mercado pago" alt="imagen de mercado pago" description="imagen de mercado pago azul">
          </div>
          <div class="col-12 col-md-6 d-flex justify-content-center align-items-center pt-3 pt-md-0">
            <h3 class="font-size-24 font-color-brown font-nuni-re text-md-left text-center mb-4 px-4">
              Paga tu contenedor a <span class="font-nuni-bo"> meses sin intereses </span>  directamente en nuestra oficina principal.
              </h3>
          </div>
        </div>
      </div>
    </div>
    <FormularioCotizacion/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import PasosCotizacion from '@/components/PasosCotizacion.vue'
import FormularioCotizacion from '@/components/FormularioCotizacion.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Cotizacion',
  components: {
    Header,
    Nav,
    PasosCotizacion,
    FormularioCotizacion,
    Footer
  }
}
</script>