<template>
  <div class="aviso">
    <Header />
    <Nav />
     <div>
      <div class="container mt-5 mb-5">
        <div class="row">          
          <div class="col-12 d-flex flex-column justify-content-center align-items-center">
              <h1 class="font-nuni-bo font-color-blue font-size-42 text-center py-3 px-0 px-sm-5 pt-5">Aviso de privacidad
            </h1>
              <pre style="white-space: pre-wrap;" class="font-size-14 font-koho-me font-color-brown text-left d-block w-100">
Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que Creativos Espacios S.A. de C.V., con domicilio en Av. del Árbol 104, Col. Lomas de San Lorenzo. C.P. 09780 Alcaldía Iztapalapa, CDMX, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha  solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos;  comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los  mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
	Nombre completo, teléfono fijo y/o celular, dirección y correo electrónico
Es importante informarle que usted tiene derecho al acceso, rectificación y cancelación de sus datos personales, a  oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado.
Para ello, es necesario que envíe la solicitud en los términos que marca la Ley antes citada en su Art. 29 al C. Edith Rámirez, responsable de nuestro Departamento de Protección de Datos Personales, ubicado en Creativos Espacios S.A de C.V., con domicilio en Av. del Árbol 104, Col. Lomas de San Lorenzo. C.P. 09780 Alcaldía Iztapalapa, CDMX.
En caso de que no desee de recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica: ventas@creativosespacios.com.mx
Importante: Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos.
Estas modificaciones estarán disponibles al público a través de nuestra página de Internet http://www.creativosespacios.com.mx  (sección aviso de privacidad).
Fecha de última actualización: 28 de Abril de 2021

</pre>
             
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Aviso',
  components: {
    Header,
    Nav,
    Footer
  }
}
</script>