<template>
  <div id="quienes-somos" class="hacemos">
    <div class="container pb-5 mb-5">
       <div class="row pb-5">
        <div class="col-12 col-md-6 ">
          <h1 class="font-nuni-bo font-color-blueblack font-size-42 text-center py-3 px-0 px-sm-5">Quiénes somos
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 back-color-blue py-5 px-0">
          <div class="font-color-white p-5 text-left">
            <h2 class="font-nuni-bo font-size-18">
              Amamos al planeta
            </h2>
            <p class="font-koho-me font-size-14">
              El planeta tierra es el único planeta que tenemos como humanidad, es el habitat de millones de formas de
              vida. Creativos Espacios se suma a su cuidado generando ideas de valor.
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 height-xs-300">
          <div class="content-img-square img-back-forest"></div>
        </div>
      </div>
      <div class="row d-flex ">
        <div class="col-12 col-md-6 order-1 order-md-0 px-0 height-xs-300">
          <div class="content-img-square img-back-colors"></div>
        </div>
        <div class="col-12 col-md-6 order-0 order-md-1 back-color-yellow py-5 px-0">
          <div class="font-color-brown p-5 text-left">
            <h2 class="font-nuni-bo font-size-18">
              Somos conscientes

            </h2>
            <p class="font-koho-me font-size-14">

              Sabiamos desde hace 10 años que el sector de la construcción es el responsable de consumir el 50% de los
              recursos naturales, el 40% de la energía y el 50% del total de los residuos generados.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 back-color-metalblack py-5 px-0">
          <div class="font-color-white p-5 text-left">
            <h2 class="font-nuni-bo font-size-18">
              Te queremos ayudar
            </h2>
            <p class="font-koho-me font-size-14">

              Conocemos a nuestros clientes, sabemos que quieres más que sólo un servicio necesitas un aliado que sea
              experto, amigable y profesional, en Creativos Espacios lo encontrarás conocerás al equipo por su nombre y
              apellido.
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 height-xs-300">
          <div class="content-img-square img-back-work"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hacemos',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>