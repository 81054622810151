<template>
  <div class="terminos">
    <Header />
    <Nav />
    <div>
      <div class="container mt-5 mb-5">
        <div class="row">          
          <div class="col-12 d-flex flex-column justify-content-center align-items-center">
              <h1 class="font-nuni-bo font-color-blue font-size-42 text-center py-3 px-0 px-sm-5 pt-5">Términos y condiciones
            </h1>
              <pre style="white-space: pre-wrap;" class="font-size-14 font-koho-me font-color-brown text-left d-block w-100">
1.	Proemio
Al ingresar y utilizar este portal de Internet, cuyo nombre de dominio es http://www.creativosespacios.com.mx, propiedad de Imagina Creativos Espacios S.A. de C.V., que en lo sucesivo se denominará Creativos Espacios el usuario está aceptando los Términos y condiciones de uso contenidos en este convenio y declara expresamente su aceptación utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 del Código Civil Federal.
En caso de no aceptar en forma absoluta y completa los términos y condiciones de este convenio, el usuario deberá abstenerse de acceder, utilizar y observar el sitio web http://www.creativosespacios.com.mx
Y en caso de que el usuario acceda, utilice y observe el sitio web http://www.creativosespacios.com.mx se considerará como una absoluta y expresa aceptación de los Términos y condiciones de uso aquí estipulados.
La sola utilización de dicha página de Internet le otorga al público en general la condición de usuario (en adelante referido como el «usuario» o los «usuarios») e implica la aceptación, plena e incondicional, de todas y cada una de las condiciones generales y particulares incluidas en estos Términos y condiciones de uso publicados por Creativos Espacios en el momento mismo en que el usuario acceda al sitio web.
Cualquier modificación a los presentes Términos y condiciones de uso será realizada cuando el titular de la misma, en este caso Creativos Espacios, lo considere apropiado, siendo exclusiva responsabilidad del usuario asegurarse de tomar conocimiento de tales modificaciones.
2.	Convenio
Convenio de adhesión para el uso de la página de Internet http://www.creativosespacios.com.mx que celebran: por una parte, Creativos Espacios y, por la otra, el usuario, sujetándose, ambas partes, a lo establecido en este documento.
3.	Licencia
	a) Por virtud de la celebración de este convenio, Creativos Espacios otorga y concede al usuario el derecho no exclusivo, revocable y no transferible de ver y utilizar el sitio web http://www.creativosespacios.com.mx de conformidad con los Términos y condiciones de uso que aquí se estipulan. Para los efectos del presente convenio, las partes acuerdan que por «usuario» se entenderá a cualquier persona de cualquier naturaleza que ingrese al sitio web http://www.creativosespacios.com.mx y/o a cualquiera de las subpáginas que despliegan su contenido y/o a la persona de cualquier naturaleza que se dé de alta y/o use cualquiera de los servicios que se ofrecen a través de dicha página.
	b) El usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en el sitio web http://www.creativosespacios.com.mx exclusivamente para uso personal, queda terminantemente prohibido el uso comercial de dicha información. En caso de ser persona moral se sujetará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor.
	c) La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, documento o gráfico que aparezca en el sitio web http://www.creativosespacios.com.mx, para cualquier uso distinto al personal no comercial le está expresamente prohibido al usuario, a menos de que cuente con la autorización previa y por escrito de Creativos Espacios.
4.	Reglas para el uso del sitio web http://www.creativosespacios.com.mx
El usuario y Creativos Espacios están de acuerdo en que la utilización del sitio web http://www.creativosespacios.com.mx se sujetará a las siguientes reglas:
1.	Información contenida en el sitio web http://www.creativosespacios.com.mx. El usuario reconoce y acepta que la información publicada o contenida en dicho sitio será claramente identificada de forma tal que se reconozca que la misma proviene y ha sido generada por Creativos Espacioso por sus proveedores.
2.	No obstante, la información, conceptos y opiniones publicadas en dicho sitio no necesariamente reflejan la posición de Creativos Espacios, ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios (en lo sucesivo los «afiliados»). Por esta razón, Creativos Espacios no se hace responsable por ninguna de las informaciones, opiniones y conceptos que se emitan en la página web referida. En este caso, se recomienda al usuario consultar con un especialista y/o profesional en la materia. Asimismo, Creativos Espacios no se hace responsable de la información contenida en la página de Internet, incluidas las subpáginas, en el entendido de que el uso y seguimiento de la misma es bajo riesgo y responsabilidad del usuario.
3.	Creativos Espacios se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información, comunicación o material que a su exclusivo juicio pueda resultar: i) abusivo, difamatorio u obsceno; ii) fraudulento, artificioso o engañoso; iii) violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero; iv) ofensivo o; v) que de cualquier forma contravenga lo establecido en este convenio. Si el usuario desea obtener mayor información de un tema en específico proveído por Creativos Espacioso sus proveedores deberá consultarlo directamente con cada uno de ellos, según corresponda, y/o con un especialista en la materia.
4.	El usuario reconoce que Creativos Espacios no controla o censura previamente el contenido disponible en la página de Internet. Por tal motivo, Creativos Espacios no asume ninguna responsabilidad por el contenido provisto a dicha página por proveedores independientes o ajenos a Creativos Espacios no tiene el control editorial sobre el contenido, información y/o material generado y/o provisto por terceros. Todas las opiniones, consejos, declaraciones, servicios, ofertas u otras informaciones o contenidos expresados o puestos a disposición del público por terceros, pertenecen a su respectivo autor y Creativos Espacios no asume responsabilidad alguna frente a ello. De la misma forma, Creativos Espacios no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por tales terceros. Adicionalmente, Creativos Espacios no es responsable ni garantiza la exactitud, exhaustividad, veracidad y/o confiabilidad de cualquier opinión, información, consejo o declaración expresados por Creativos Espaciosa través de su página de Internet y bajo ninguna circunstancia Creativos Espacios será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza que el usuario deposite en información obtenida a través de su sitio web. Creativos Espacios se reserva el derecho de suprimir o modificar el contenido de esta página que, a exclusivo juicio de Creativos Espacios, no cumpla con sus estándares o que pudiera resultar contrario al ordenamiento jurídico vigente y, por consiguiente, no será responsable por cualquier falla o tardanza que se genere al eliminar tal material
5.	Formatos
Los usuarios reconocen que, al proporcionar la información de carácter personal requerida en alguno de los servicios que se prestan en este sitio web, otorgan a Creativos Espacios la autorización señalada en el artículo 109 de la Ley Federal del Derecho de Autor. En todos los casos, los usuarios responderán por la veracidad de la información proporcionada a Creativos Espacios.
Asimismo, para la prestación de servicios, el usuario se obliga además a aceptar los términos y condiciones estipulados para tal efecto.
6.	Derechos de autor y propiedad industrial
Creativos Espacios, el sitio web http://www.creativosespacios.com.mx sus logotipos y todo el material que aparece en dicho sitio, son marcas, nombres de dominio, nombres comerciales y obras artísticas propiedad de sus respectivos titulares y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual y derechos de autor.
Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones, y en general cualquier información contenida o publicada en el sitio web http://www.creativosespacios.com.mx se encuentran debidamente protegidos a favor de Creativos Espacios, sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual e industrial.
Se prohíbe expresamente al usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la información contenida en el sitio señalado.
En caso de que el usuario transmita a Creativos Espacios cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través del sitio web http://www.creativosespacios.com.mx el usuario otorga con este acto a Creativos Espacios una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente.
Lo establecido en el párrafo anterior se aplicará igualmente a cualquier otra información que el usuario envíe o transmita a Creativos Espacios, incluyendo, sin limitación alguna, ideas para renovar o mejorar el sitio web http://www.creativosespacios.com.mx ya sea que éstas hayan sido incluidas en cualquier espacio de la página señalada o en virtud de otros medios o modos de transmisión conocidos o que sean desarrollados en el futuro.
Por lo anterior, el usuario renuncia expresamente con este acto a llevar a cabo cualquier acción, demanda o reclamación en contra de Creativos Espacios sus afiliados o proveedores por cualquier actual o eventual violación de cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software, ideas y demás material que el propio usuario envíe al sitio web http://www.creativosespacios.com.mx
Es nuestra política actuar contra las violaciones que en materia de propiedad intelectual se pudieran generar u originar según lo estipulado en la legislación y en otras leyes de propiedad intelectual aplicables, incluyendo la eliminación o el bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad intelectual de terceros.
En caso de que algún usuario o tercero consideren que cualquiera de los contenidos que se encuentren o sean introducidos en dicho http://www.creativosespacios.com.mx y/o cualquiera de sus servicios, violen sus derechos de propiedad intelectual deberán enviar una notificación a la siguiente dirección (Poner dirección de correo electrónico), en la que indiquen: i) datos personales verídicos (nombre, dirección, número de teléfono y dirección de correo electrónico del reclamante); ii) firma autógrafa con los datos personales del titular de los derechos de propiedad intelectual; iii) indicación precisa y completa del (los) contenido (s) protegido (s) mediante los derechos de propiedad intelectual supuestamente infringidos, así como la localización de dichas violaciones en el sitio web referido; iv) declaración expresa y clara de que la introducción del (los) contenido (s) indicado (s) se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; v) declaración expresa, clara y bajo la responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del (los) contenido (s) constituye una violación de dichos derechos.
7.	Material publicitario
El usuario reconoce y acepta que Creativos Espacios es una organización independiente de terceros patrocinadores y anunciantes cuya información, imágenes, anuncios y demás material publicitario o promocional (en lo subsecuente «material publicitario›) puede ser publicado en el sitio web http://www.creativosespacios.com.mx
El usuario reconoce y acepta que el material publicitario no forma parte del contenido principal que se publica en dicho sitio. Asimismo, reconoce y acepta con este acto que este material se encuentra protegido por las leyes que en materia de propiedad intelectual e industrial resulten aplicables.
8.	Negación de garantías
El usuario está de acuerdo que la utilización del sitio web http://www.creativosespacios.com.mx se realiza bajo su propio riesgo y que los servicios y productos que ahí se prestan y ofrecen se prevén sobre una base «tal cual» y «según sean disponibles». Creativos Espacios no garantiza que la página señalada satisfaga los requerimientos del usuario o que los servicios que en ella se ofrecen no sufran interrupciones, sean seguros o estén exentos de errores.
Creativos Espacios no garantiza o avala en ninguna forma la veracidad, precisión, legalidad, moralidad o ninguna otra característica del contenido del material que se publique en el sitio web http://www.creativosespacios.com.mx
Creativos Espacios se libera de cualquier responsabilidad y condiciones, tanto expresas como implícitas, en relación con los servicios e información contenida o disponible en o a través de esta página web; incluyendo, sin limitación alguna:
	a) La disponibilidad de uso del sitio web http://www.creativosespacios.com.mx
	b) La ausencia de virus, errores, desactivadores o cualquier otro material contaminante o con funciones destructivas en la información o programas disponibles en o a través de esta página o en general cualquier falla en dicho sitio.
	c) No obstante lo anterior, Creativos Espacioso sus proveedores podrán actualizar el contenido de la página constantemente, por lo que se solicita al usuario tomar en cuenta que algunas informaciones publicitadas o contenidas en o a través de este sitio web pueden haber quedado obsoletas y/o contener imprecisiones o errores tipográficos u ortográficos.
9.	Limitaciones a la responsabilidad
Hasta el máximo permitido por las leyes aplicables, Creativos Espacios no será responsable, en ningún caso, por daños directos, especiales, incidentales, indirectos, o consecuenciales que en cualquier forma se deriven o se relacionen con:
	a) El uso o ejecución del sitio web http://www.creativosespacios.com.mx, con el retraso o la falta de disponibilidad de uso de Creativos Espacios
	b) La proveeduría o falta de la misma de servicios de cualquier información o gráficos contenidos o publicados en o a través del sitio señalado.
	c) La actualización o falta de actualización de la información.
	d) La alteración o modificación, total o parcial, de la información después de haber sido incluida en dicho sitio.
	e) Cualquier otro aspecto o característica de la información contenida o publicada en la página web o a través de las ligas que eventualmente se incluyan en este sitio.
	f) La proveeduría o falta de proveeduría que los demás servicios, todos los supuestos anteriores serán vigentes, aún en los casos en que se le hubiere notificado o avisado a Creativos Espacios acerca de la posibilidad de que se ocasionarán dichos daños.
10.	Modificaciones al sitio web http://www.creativosespacios.com.mx
Creativos Espacios podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al usuario, realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios, áreas, bases de datos y demás elementos de dicho sitio, sin que ello de lugar ni derecho a ninguna reclamación o indemnización, ni que esto implique reconocimiento de responsabilidad alguna a favor del usuario.
11.	Modificaciones al convenio
Creativos Espacios se reserva el derecho de modificar los Términos y condiciones de uso de este convenio en cualquier momento, siendo efectivas dichas modificaciones de forma inmediata por medio de:
	a) La publicación en el sitio web http://www.creativosespacios.com.mx del convenio modificado.
	b) La notificación al usuario sobre dichas modificaciones.
De esta forma, el usuario está de acuerdo en revisar dicho convenio periódicamente con la finalidad de mantenerse al tanto de dichas modificaciones. No obstante, lo anterior, cada vez que el usuario acceda al sitio señalado se considerará como una aceptación absoluta a las modificaciones del presente convenio.
12.	Términos adicionales
Ocasionalmente, Creativos Espacios podrá agregar a los Términos y condiciones de uso del presente convenio provisiones adicionales relativas a áreas específicas o nuevos servicios que se proporcionen en o a través del sitio web http://www.creativosespacios.com.mx (en lo subsecuente «términos adicionales»), los cuales serán publicados en las áreas específicas o nuevos servicios de dicho sitio para su lectura y aceptación. El usuario reconoce y acepta que estos términos adicionales forman parte integrante del presente convenio para todos los efectos legales a que haya lugar.
13.	Cesión de derechos
Creativos Espacios podrá, en cualquier momento y cuando así lo estime conveniente, ceder total o parcialmente sus derechos y obligaciones derivados del presente convenio. En virtud de dicha cesión, Creativos Espacios quedará liberada de cualquier obligación a favor del usuario, establecida en el presente convenio.
14.	Indemnización
El usuario está de acuerdo en indemnizar a Creativos Espacios, sus afiliados, proveedores, vendedores y asesores por cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualquier incumplimiento por parte del usuario al presente convenio; incluyendo, sin limitación de alguna de las derivadas de:
	a) Cualquier aspecto relativo al uso del sitio web http://www.creativosespacios.com.mx
	b) La información contenida o disponible en o a través de dicho sitio o de injurias, difamación o cualquier otra conducta violatoria del presente convenio por parte del usuario en el uso de la página web señalada.
	c) La violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad intelectual, contenidos o disponibles en, o a través de dicho sitio web.
15.	Terminación
Creativos Espacios se reserva el derecho, a su exclusiva discreción, y sin necesidad de aviso o notificación al usuario, para:
	a) Terminar definitivamente el presente convenio.
	b) Descontinuar o dejar de publicar definitivamente el sitio web http://www.creativosespacios.com.mx sin responsabilidad alguna para Creativos Espacios sus afiliados o proveedores.
Subsistencia
Estos Términos y condiciones de uso, así como los términos adicionales, constituyen el acuerdo íntegro entre las partes, y sustituye cualquier otro acuerdo o convenio celebrado con anterioridad. Cualquier cláusula o provisión del presente convenio, así como de los términos adicionales, legalmente declarada inválida, será eliminada o modificada a elección de Creativos Espacios, con la finalidad de corregir su vicio o defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza, obligatoriedad y validez.
17.	No renuncia de derechos
La inactividad por parte de Creativos Espacios, sus afiliados o proveedores al ejercicio de cualquier derecho o acción derivados del presente convenio, en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.
18.	Legislación aplicable y jurisdicción
Este convenio estará sujeto y será interpretado de acuerdo con las leyes y ante los tribunales del Distrito Federal, México.
</pre>
             
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Terminos',
  components: {
    Header,
    Nav,
    Footer
  }
}
</script>