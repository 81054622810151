<template>
  <div class="sitemap">
    <Header />
    <Nav />
    <div class="container mt-5 pt-5 pb-5">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Inicio</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de servicio del inicio" alt="Ir a la sección de resumen de servicios del inicio" class="ancor-style-generic font-color-brown p-1" href="/#servicios-inicio">Servicios</a></li>
            <li class="my-2"><a title="Ir a la sección de venta del inicio" alt="Ir a la sección de resumen de venta del inicio" class="ancor-style-generic font-color-brown p-1" href="/#venta-de-contenedores">Venta de contenedores maritimos</a></li>
            <li class="my-2"><a title="Ir a la sección de renta del inicio" alt="Ir a la sección de resumen de renta del inicio" class="ancor-style-generic font-color-brown p-1" href="/#renta-de-contenedores">Renta de contenedores maritimos</a></li>
            <li class="my-2"><a title="Ir a la sección de modificación del inicio" alt="Ir a la sección de resumen de modificación del inicio" class="ancor-style-generic font-color-brown p-1" href="/#modificacion-de-contenedores">Modificación de contenedores maritimos</a></li>
            <li class="my-2"><a title="Ir a la sección de beneficios del inicio" alt="Ir a la sección de beneficios del inicio" class="ancor-style-generic font-color-brown p-1" href="/#beneficios">Beneficios</a></li>
          </ul>
        </div>
         <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Conócenos</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de historia de conócenos" alt="Ir a la sección de resumen de historia de conócenos" class="ancor-style-generic font-color-brown p-1" href="/conocenos#historia">Nuestra historia</a></li>
            <li class="my-2"><a title="Ir a la sección de quienes somos de conócenos" alt="Ir a la sección de resumen de quienes somos de conócenos" class="ancor-style-generic font-color-brown p-1" href="/conocenos#quienes-somos">Quiénes somos</a></li>
            <li class="my-2"><a title="Ir a la sección de valores de conócenos" alt="Ir a la sección de resumen de valores de conócenos" class="ancor-style-generic font-color-brown p-1" href="/conocenos#valores">Valores</a></li>
            <li class="my-2"><a title="Ir a la sección de clientes de conócenos" alt="Ir a la sección de resumen de clientes de conócenos" class="ancor-style-generic font-color-brown p-1" href="/conocenos#clientes">Clientes</a></li>
          </ul>
        </div>
         <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Tipos de contenedor</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de contenedores de tipos de contenedores" alt="Ir a la sección de resumen de contenedores de tipos de contenedores" class="ancor-style-generic font-color-brown p-1" href="/tipos#contenedores-maritimos">Contenedores marítimos</a></li>
            <li class="my-2"><a title="Ir a la sección de videos de tipos de contenedores" alt="Ir a la sección de resumen de videos de tipos de contenedores" class="ancor-style-generic font-color-brown p-1" href="/tipos#videos-contenedores">Videos</a></li>
            <li class="my-2"><a title="Ir a la sección de imagenes de tipos de contenedores" alt="Ir a la sección de resumen de imagenes de tipos de contenedores" class="ancor-style-generic font-color-brown p-1" href="/tipos#imagenes-contenedores">Imagenes</a></li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Adecuaciones</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de tipo de adecuaciones" alt="Ir a la sección de resumen de tipo de adecuaciones" class="ancor-style-generic font-color-brown p-1" href="/adecuaciones#tipos">Tipos de adecuaciones</a></li>
            <li class="my-2"><a title="Ir a la sección de pasos de adecuaciones" alt="Ir a la sección de resumen de pasos de adecuaciones" class="ancor-style-generic font-color-brown p-1" href="/adecuaciones#pasos">Pasos adecuaciones</a></li>
                        <li class="my-2"><a title="Ir a la sección de videos de adecuaciones" alt="Ir a la sección de resumen de videos de adecuaciones" class="ancor-style-generic font-color-brown p-1" href="/adecuaciones#videos-adecuaciones">Videos</a></li>
            <li class="my-2"><a title="Ir a la sección de imagenes de adecuaciones" alt="Ir a la sección de resumen de imagenes de adecuaciones" class="ancor-style-generic font-color-brown p-1" href="/adecuaciones#imagenes-adecuaciones">Imagenes</a></li>
          </ul>
        </div>
         <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Cotización</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de información de cotización" alt="Ir a la sección de resumen de información de cotización" class="ancor-style-generic font-color-brown p-1" href="/cotizacion">Información de cotización</a></li>
            <li class="my-2"><a title="Ir a la sección de formulario de cotización" alt="Ir a la sección de resumen de formulario de cotización" class="ancor-style-generic font-color-brown p-1" href="/cotizacion">Formulario de cotización</a></li>            
          </ul>
        </div>
         <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Localización</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de contacto de localización" alt="Ir a la sección de resumen de contacto de localización" class="ancor-style-generic font-color-brown p-1" href="/localizacion">Contacto</a></li>
            <li class="my-2"><a title="Ir a la sección de oficina central de localización" alt="Ir a la sección de resumen de oficina centrals de localización" class="ancor-style-generic font-color-brown p-1" href="/localizacion">Oficina central</a></li>
            <li class="my-2"><a title="Ir a la sección de patio de contenedores de localización" alt="Ir a la sección de resumen de patio de contenedores de localización" class="ancor-style-generic font-color-brown p-1" href="/localizacion">Patio de contenedores</a></li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4 my-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-brown text-left">Ayuda</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a la sección de preguntas frecuentes de ayuda" alt="Ir a la sección de resumen de preguntas frecuentes de ayuda" class="ancor-style-generic font-color-brown p-1" href="/preguntas">Preguntas frecuentes</a></li>
            <li class="my-2"><a title="Ir a la sección de términos y condiciones de ayuda" alt="Ir a la sección de resumen de términos y condiciones de ayuda" class="ancor-style-generic font-color-brown p-1" href="/terminos">Términos y condiciones</a></li>
            <li class="my-2"><a title="Ir a la sección de aviso de identidad de ayuda" alt="Ir a la sección de resumen de aviso de identidad de ayuda" class="ancor-style-generic font-color-brown p-1" href="/aviso">Aviso de identidad</a></li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Sitemap',
  components: {
    Header,
    Nav,
    Footer
  }
}
</script>