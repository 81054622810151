<template>
  <div class="opcionesPreguntas">
    <div class="container-fluid section-banner-styles back-color-bluem-bluel mt-5 pb-5 position-relative">
      <div class="triangle-top-left "><img class="img-triangle-tl" src="@/assets/img/preguntas/triangle-top-left.png"
          title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-top-right "><img class="img-triangle-tr" src="@/assets/img/preguntas/triangle-top-right.png"
          title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
     
      <div class="container">

        <div class="row">
          <div class="col-12">
            <h1 class="font-nuni-bo font-color-white font-size-42 text-center py-3 px-0 px-sm-5 pt-5">Preguntas frecuentes
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-card class="option-section-styles" no-body>
              <b-tabs pills card>

                <b-tab active>
                  <template #title>
                    <img class="img-fluid icon-footer-size" src="@/assets/img/preguntas/icon-wallet-brown.svg" title="preguntas frecuentes acerca del pago" alt="icono de cartera" description="icono de cartera cafe">
                    <span class="font-color-brown p-1 pl-3">Pagos</span>
                  </template>
                  <b-card-text>
                    <div>
                      <b-button v-b-toggle.pagos-1
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Aceptan tarjeta de crédito?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-1" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, aceptamos tarjeta de crédito y/o débito. También podemos diferir su compra a meses sin
                            intereses.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-2
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Puedo pagar en efectivo?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-2" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si recibimos pagos en efectivo.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-3
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿El costo puede aumentar durante la
                          obra o ya es fijo?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-3" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Ya es fijo de acuerdo a la cotización que se ofreció desde un principio, el costo no cambia
                            a menos que el cliente indique algún cambio de imprevisto.
                            <br>
                            El precio se mantiene en lo pactado El precio siempre se mantendrá en lo pactado siempre y
                            cuando no pase la fecha de vencimiento generalmente se establecen de 7 a 10 días y si en ese
                            lapso no se a confirmado la compra los precios y tipo de cambio pueden variar.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-4
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿El precio incluye IVA?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-4" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Todos los precios y servicios son más el impuesto del 16% I.V.A.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-5
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Existe alguna clase de descuento o
                          promociones?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-5" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, mantente pendiente de nuestras redes sociales para más información.

                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-6
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Se aceptan devoluciones?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-6" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            No, no hay devoluciones
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-card-text>
                </b-tab>
                 <b-tab >
                  <template #title>
                    <img class="img-fluid icon-footer-size" src="@/assets/img/preguntas/icon-square-brown.svg" title="preguntas frecuentes acerca del transporte y envío" alt="icono de caja" description="icono de caja café">
                    <span class="font-color-brown p-1 pl-3">Transporte y envío</span>
                  </template>
                  <b-card-text>
                    <div>
                      <b-button v-b-toggle.pagos-1
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cómo saber si entra a mi localidad?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-1" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Comunícate con nuestros asesores para saber la disponibilidad.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-2
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿El costo de envío está incluido en el precio?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-2" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            No, lamentablemente el costo es variable de acuerdo al lugar de la entrega.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-3
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cuesta lo mismo enviar cualquier modelo de contenedor?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-3" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            No, pero el envío depende de las condiciones de tu localidad
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab >
                  <template #title>
                    <img class="img-fluid icon-footer-size" src="@/assets/img/preguntas/icon-paper-brown.svg" title="preguntas frecuentes acerca de la documentación" alt="icono de clip" description="icono de clip cafe">
                    <span class="font-color-brown p-1 pl-3">Cotización</span> <img class="img-fluid img-dog-brown" src="@/assets/img/preguntas/img-dog-brown.png" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                  </template>
                  <b-card-text>
                    <div>
                      <b-button v-b-toggle.pagos-1
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Atienden cotizaciones por teléfono?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-1" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, contamos con asesoría y cotización telefónica
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-2
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Hay algún horario de atención?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-2" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, de 9:00 hrs a 18:00 hrs
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-3
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Es posible asistir a la sucursal?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-3" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           Si, es lo más recomendable pero en caso de no poder hacerlo podemos mostrarte fotografías y video del producto
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-4
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Puedo contratar solo el servicio de adecuación aún que no haya comprado el contenedor con ustedes?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-4" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, pero nos gustaría que compartieras tu idea o tus planos para ver si es posible
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-5
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Hay un límite de compra?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-5" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           No, pero depende mucho de la cantidad en el inventario
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>                  
                  </b-card-text>
                </b-tab>
                <b-tab >
                  <template #title>
                    <img class="img-fluid icon-footer-size" src="@/assets/img/preguntas/icon-clip-brown.svg" title="preguntas frecuentes acerca de la documentación" alt="icono de clip" description="icono de clip cafe">
                    <span class="font-color-brown p-1 pl-3">Documentación</span>
                  </template>
                  <b-card-text>
                    <div>
                      <b-button v-b-toggle.pagos-1
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cómo sé si el contenedor es seguro para su uso en proyectos?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-1" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Contamos con la información acerca del uso del contenedor y sólo trabajamos con contenedores que cumplen nuestra revisión para comercializarse.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-2
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cuáles son las normas bajo las que trabajan?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-2" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Normas mexicanas.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-3
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Se me entregarán documentos de propiedad sobre el contenedor? </span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-3" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           Si se entrega factura y pedimento de importación definitiva.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-4
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Puedo verificar la procedencia del producto?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-4" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           Si.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-5
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Hay alguna documentación que deba presentar para rentar?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-5" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, Acta Constitutiva y/o poder notarial IFE o INE, Comprobante de domicilio y se firma un contrato de Arrendamiento.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>                  
                  </b-card-text>
                </b-tab>
                <b-tab >
                  <template #title>
                    <img class="img-fluid icon-footer-size" src="@/assets/img/preguntas/icon-phone-brown.svg" title="preguntas frecuentes acerca de la asesoría" alt="icono de teléfono" description="icono de teléfono cafe">
                    <span class="font-color-brown p-1 pl-3">Asesoría</span>
                  </template>
                  <b-card-text>
                    <div>
                      <b-button v-b-toggle.pagos-1
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Por cuál medio se recibe la asesoría?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-1" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           Telefónico, redes sociales, email y en nuestra dirección.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-2
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Funciona las 24 horas?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-2" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            No, tenemos un horario de 9:00 hrs a 18:00 hrs de lunes a viernes.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-3
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Tiene algún costo la asesoría?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-3" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           No, es totalmente gratuita.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-4
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Hay asesoría en cualquier parte del proceso?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-4" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Si, te podemos ayudar en todo momento.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-5
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Me puede ayudar a decidir la mejor opción?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-5" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Claro, te podemos guiar para que obtengas el mejor producto o servicio.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>                    
                  </b-card-text>
                </b-tab>
                <b-tab >
                  <template #title>
                    <img class="img-fluid icon-footer-size" src="@/assets/img/preguntas/icon-water-brown.svg" title="preguntas frecuentes acerca de la ecología" alt="icono de gota" description="icono de gota cafe">
                    <span class="font-color-brown p-1 pl-3">Ecología</span>
                  </template>
                  <b-card-text>
                    <div>
                      <b-button v-b-toggle.pagos-1
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Algún material usado daña el medio ambiente?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-1" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            El daño ecológico es mínimo en comparación a otras técnicas usadas.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-2
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cuentan con algún certificado?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-2" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            En este instante estamos tramitando los documentos y evaluaciones correspondientes para validar nuestro compromiso.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-3
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cuáles son las formas en las que ayudan ecológicamente al planeta?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-3" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                           En la sección de Nosotros puedes encontrar más información.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-4
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Cuáles son sus procesos de reciclaje?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-4" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            Las tres ¨R¨ son parte fundamental en cada obra, todo material sobrante es llevado a casas de reciclaje o re utilizado en nuevas obras para asi reducir los desperdicios.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                    <div class="mt-4">
                      <b-button v-b-toggle.pagos-5
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Toman otras medidas para proteger la ecología?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-5" class="mt-0 ">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            En algunas zonas especiales trabajamos bajo las normas que protegen las condiciones locales.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>  
                     <div class="mt-4">
                      <b-button v-b-toggle.pagos-6
                        class="button-question-style d-flex justify-content-between align-items-center w-100 py-3 px-4 ">
                        <span class="font-nuni-re font-color-brown font-size-24">¿Por qué hay tantos perritos en nuestras instalaciones?</span>
                        <img src="@/assets/img/preguntas/icon-arrow-brown.svg" title="mostrar u ocultar respuesta" alt="icono de flecha" description="icono de flecha café">
                      </b-button>
                      <b-collapse id="pagos-6" class="mt-0">
                        <b-card class="card-question-style">
                          <p class="card-text text-left font-nuni-re font-color-brown font-size-24">
                            A veces rescatamos perritos en situación de abandono, cerca de nuestras localidades contribuimos cuidándolos y esterilizándolos.
                          </p>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-card-text>
                </b-tab>               
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpcionesPreguntas',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>