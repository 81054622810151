<template>
  <div class="conocenos">
    <Header />
    <Nav />
    <Historia/>
    <Hacemos/>
    <Valores/>
    <Clientes/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Historia from '@/components/Historia.vue'
import Hacemos from '@/components/Hacemos.vue'
import Valores from '@/components/Valores.vue'
import Clientes from '@/components/Clientes.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Conocenos',
  components: {
    Header,
    Nav,
    Historia,
    Hacemos,
    Valores,
    Clientes,
    Footer
  }
}
</script>