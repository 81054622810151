<template>
  <div class="preguntas">
    <Header />
    <Nav />
    <OpcionesPreguntas />    
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import OpcionesPreguntas from '@/components/OpcionesPreguntas.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Preguntas',
  components: {
    Header,
    Nav,
    OpcionesPreguntas,    
    Footer
  }
}
</script>