<template>
  <div id="pasos" class="pasosAdecuaciones mt-5 pb-5">
  
    <div class="container">

      <div class="row back-color-blue position-relative py-5 py-md-4">
        <div class="square-blue-right position-absolute w-100 h-100 d-flex justify-content-end">
          <div class="h-100 w-50 border-color-white-bottom position-relative"></div>
          <div class="h-100 w-25 back-color-blueblack border-color-white-left position-relative"></div>
        </div>
         <div class="square-yellow-left position-absolute back-color-yellow d-flex justify-content-center align-items-center">
            <span class="font-size-48 font-color-brown">1</span>
            </div>
        <div class="col-12 col-md-8 position-relative d-flex justify-content-center align-items-center py-5 py-md-0">         
          <h2 class="font-color-white font-nuni-bo font-size-48">Tienes una <br> idea</h2>
        </div>
        <div class="col-12 col-md-4 pr-4">
          <img class="img-fluid" src="@/assets/img/adecuaciones/paso-1-adecuaciones.png" title="ideas" alt="imagen de hombre con ideas" description="imagen de hombre con ideas en pizarrón">
        </div>
      </div>

      <div class="row back-color-blue position-relative py-5 py-md-4">
        <div class="square-blue-right position-absolute w-100 h-100 d-flex justify-content-start">
          <div class="h-100 w-25 back-color-blueblack border-color-white-right position-relative"></div>
          <div class="h-100 w-50 border-color-white-bottom position-relative"></div>          
        </div>
         <div class="square-yellow-right position-absolute back-color-yellow d-flex justify-content-center align-items-center">
            <span class="font-size-48 font-color-brown">2</span>
            </div>
       
        <div class="col-12 col-md-4 pr-4 py-5 py-md-0">
          <img class="img-fluid" src="@/assets/img/adecuaciones/paso-2-adecuaciones.png" title="llamando por telefóno" alt="imagen de hombre llamando por telefóno" description="imagen de hombre llamando por telefóno en la calle">
        </div>
         <div class="col-12 col-md-8 position-relative d-flex justify-content-center align-items-center">         
          <h2 class="font-color-white font-nuni-bo font-size-48">Contáctanos</h2>
        </div>
      </div>


       <div class="row back-color-blue position-relative py-5 py-md-4">
         <div class="square-blue-right position-absolute w-100 h-100 d-flex justify-content-end">
          <div class="h-100 w-50 border-color-white-bottom position-relative"></div>
          <div class="h-100 w-25 back-color-blueblack border-color-white-left position-relative"></div>
        </div>
         <div class="square-yellow-left position-absolute back-color-yellow d-flex justify-content-center align-items-center">
            <span class="font-size-48 font-color-brown">3</span>
            </div>
        <div class="col-12 col-md-8 position-relative d-flex justify-content-center align-items-center py-5 py-md-0">         
          <h2 class="font-color-white font-nuni-bo font-size-48">Te ayudamos <br>con  tu idea</h2>
        </div>
        <div class="col-12 col-md-4 pr-4">
          <img class="img-fluid" src="@/assets/img/adecuaciones/paso-3-adecuaciones.png" title="ajustando planos" alt="imagen de mujer ajustando planos" description="imagen de mujer ajustando planos en la computadora">
        </div>
      </div>


      <div class="row back-color-blue position-relative py-5 py-md-4">
         <div class="square-blue-right position-absolute w-100 h-100 d-flex justify-content-start">
          <div class="h-100 w-25 back-color-blueblack border-color-white-right position-relative"></div>
          <div class="h-100 w-50 border-color-white-bottom position-relative"></div>          
        </div>
         <div class="square-yellow-right position-absolute back-color-yellow d-flex justify-content-center align-items-center">
            <span class="font-size-48 font-color-brown">4</span>
            </div>
       
        <div class="col-12 col-md-4 pr-4 py-5 py-md-0">
          <img class="img-fluid" src="@/assets/img/adecuaciones/paso-4-adecuaciones.png" title="analizando planos" alt="imagen de pareja analizando planos" description="imagen de pareja analizando planos en la oficina">
        </div>
         <div class="col-12 col-md-8 position-relative d-flex justify-content-center align-items-center">         
          <h2 class="font-color-white font-nuni-bo font-size-48">Analizamos <br> el alcance</h2>
        </div>
      </div>


            <div class="row back-color-blue position-relative py-5 py-md-4">
         <div class="square-blue-right position-absolute w-100 h-100 d-flex justify-content-end">
          <div class="h-100 w-50 border-color-white-bottom position-relative"></div>
          <div class="h-100 w-25 back-color-blueblack border-color-white-left position-relative"></div>
        </div>
         <div class="square-yellow-left position-absolute back-color-yellow d-flex justify-content-center align-items-center">
            <span class="font-size-48 font-color-brown">5</span>
            </div>
        <div class="col-12 col-md-8 position-relative d-flex justify-content-center align-items-center py-5 py-md-0">         
          <h2 class="font-color-white font-nuni-bo font-size-48">Construimos</h2>
        </div>
        <div class="col-12 col-md-4 pr-4">
          <img class="img-fluid" src="@/assets/img/adecuaciones/paso-5-adecuaciones.png" title="transformación de contenedores marítimos" alt="imagen de construcción de oficinas de contenedores marítimos" description="imagen de construcción de oficinas de contenedores marítimos en patio">
        </div>
      </div>


      <div class="row back-color-blue position-relative py-5 py-md-4">
         <div class="square-blue-right position-absolute w-100 h-100 d-flex justify-content-start">
          <div class="h-100 w-25 back-color-blueblack border-color-white-right position-relative"></div>
          <div class="h-100 w-50 border-color-white-bottom position-relative"></div>          
        </div>
         <div class="square-yellow-right position-absolute back-color-yellow d-flex justify-content-center align-items-center">
            <span class="font-size-48 font-color-brown">6</span>
            </div>
       
        <div class="col-12 col-md-4 pr-4 py-5 py-md-0">
          <img class="img-fluid" src="@/assets/img/adecuaciones/paso-6-adecuaciones.png" title="casa hecha con contenedores marítmos" alt="imagen de casa hecha con contenedores marítmos" description="imagen de casa hecha con contenedores marítmos en terreno">
        </div>
         <div class="col-12 col-md-8 position-relative d-flex justify-content-center align-items-center">         
          <h2 class="font-color-white font-nuni-bo font-size-48">Entregamos</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasosAdecuaciones',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>