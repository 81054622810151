<template>
  <div class="pasosCotizacion mt-5">
    <div class="container mb-5 position-relative">
      <div class="row ">
        <div class="position-absolute w-100 h-50 back-color-blueblack"></div>
        <div class="col-12">
          <h1 class="font-nuni-bo font-color-white font-size-42 text-center py-3 px-0 px-sm-5">Cotización
          </h1>
        </div>
      </div>
      <div class="row">
      
        <div class="col-12 col-md-4">
          <div class="mx-3 back-color-white border-color-blue h-100 d-flex flex-column justify-content-between">
            <div class="back-color-yellowlight  py-3">
              <h2 class="font-size-18 font-color-brown font-nuni-re text-center mb-0">Pasos para comprar</h2>
            </div>
            <div class="h-100">
              <div class="py-3">
                <img class="img-fluid" src="@/assets/img/cotizacion/img-sold-container.png" title="Comprar contenedor" alt="imagen de contenedor marítimo nuevo" description="imagen de contenedor marítimo azul">
              </div>
              <div>
                <ol class="font-size-14 font-koho-me font-color-brown text-left">
                  <li class="mb-2">Contáctanos o visítanos para asesortarte</li>
                  <li class="mb-2">Selecciona el contenedor que quieres comprar</li>                                   
                  <li class="mb-2">Te entregamos tu contenedor y su documentación</li>
                </ol>
              </div>
            </div>
            <div class="d-flex justify-content-around w-100 pb-lg-0">
              <a title="Ir al formulario de compra" alt="Ir al formulario de compra en creativos.com.mx" class="button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100" href="#comprar">Comprar</a>
            </div>
          </div>

        </div>
          <div class="col-12 col-md-4 pt-5 pt-md-0">
          <div class="mx-3 back-color-white border-color-blue h-100 d-flex flex-column justify-content-between">
            <div class="back-color-yellowlight  py-3">
              <h2 class="font-size-18 font-color-brown font-nuni-re text-center mb-0">Pasos para rentar</h2>
            </div>
            <div class="h-100">
              <div class="py-3">
                <img class="img-fluid" src="@/assets/img/cotizacion/img-rent-container.png" title="Renta de contenedor" alt="imagen de contenedor marítimo usado" description="imagen de contenedor marítimo verde">
              </div>
              <div>
                <ol class="font-size-14 font-koho-me font-color-brown text-left">
                  <li class="mb-2">Cuéntanos cuántos contenedores quieres rentar y por cuántos meses</li>
                  <li class="mb-2">Platícanos acerca del lugar dónde lo necesitas</li>
                  <li class="mb-2">Realiza el depósito de garantía</li>
                  <li class="mb-2">Se firma un contrato personalizado</li>
                  <li class="mb-2">Te entregamos tu contenedor</li>
                </ol>
              </div>
            </div>
            <div class="d-flex justify-content-around w-100 pb-lg-0">
             <a title="Ir al formulario de renta" alt="Ir al formulario de renta en creativos.com.mx" class="button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100" href="#rentar">Rentar</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 pt-5 pt-md-0">
          <div class="mx-3 back-color-white border-color-blue h-100 d-flex flex-column justify-content-between">
            <div class="back-color-yellowlight  py-3">
              <h2 class="font-size-18 font-color-brown font-nuni-re text-center mb-0">Pasos para modificar</h2>
            </div>

            <div class="h-100">
              <div class="py-3 ">
                <img class="img-fluid " src="@/assets/img/cotizacion/img-transform-container.png" title="Casa de contenedor" alt="imagen de casa de contenedor marítimo" description="imagen de casa contenedor marítimo café">
              </div>
              <div>
                <ol class="font-size-14 font-koho-me font-color-brown text-left">
                  <li class="mb-2">Contáctanos o visítanos para asesortarte</li>
                  <li class="mb-2">Hablanos del proyecto (terreno, uso, idea, planos, etc)</li>
                  <li class="mb-2">Trabajamos en la revisión de tu idea y planos para ofrecerte la mejor solución</li>
                  <li class="mb-2">Se firma un contrato personalizado</li>
                  <li class="mb-2">Te entregamos tu proyecto y su documentación</li>
                </ol>
              </div>
            </div>
            <div class="d-flex justify-content-around w-100 pb-lg-0">
             <a title="Ir al formulario de transformación" alt="Ir al formulario de transformación en creativos.com.mx" class="button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100" href="#modificar">Modificar</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasosCotizacion',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>