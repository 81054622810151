<template>
  <div class="datosLocalizacion mt-5">
    <div class="container pb-5 mb-5 position-relative">
      <div class="row ">
        <div class="position-absolute w-100 h-50 back-color-bluebold"></div>
        <div class="col-12">
          <h1 class="font-nuni-bo font-color-white font-size-42 text-center py-3 px-0 px-sm-5">Localización
          </h1>
        </div>
      </div>
      <div class="row wrap-anywhere">
        <div class="col-12 col-md-4">
          <div class="mx-0 mx-lg-3 back-color-white border-color-blue h-100 d-flex flex-column justify-content-between">
            <div class="back-color-bluelight">
              <div class="color-back-yellow">
                <img class="img-fluid icon-loca-yellow" src="@/assets/img/localizacion/icon-message-yellow.svg" title="correo electrónico" alt="icono de correo electrónico" description="icono de correo electrónico amarillo">
              </div>
            </div>
            <div class="h-100">
              <div class="pt-5">
                <h2 class="font-size-18 font-color-blue font-nuni-re text-left mb-4 px-4">Correo electrónico</h2>
                <p class="font-size-14 font-koho-me text-left px-4"> <span
                    class="d-block font-color-brown">Ventas:</span>
                  <a title="Correo de ventas" alt="Correo de ventas de creativos espacios" href="mailto:ventas@creativosespacios.mx"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">ventas@creativosespacios.mx</a>

                  <span class="d-block font-color-brown">Proyectos:</span>
                  <a title="Correo de proyectos" alt="Correo de proyectos de creativos espacios" href="mailto:proyectos@creativosespacios.mx"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">proyectos@creativosespacios.mx</a>
                  <span class="d-block font-color-brown">Facturación:</span>
                  <a title="Correo de facturación" alt="Correo de facturación de creativos espacios" href="mailto:facturacion@creativosespacios.mx"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">facturacion@creativosespacios.mx</a>
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-around w-100 pb-lg-0">
              <a title="Ir a comprar" alt="Ir a comprar contenedores marítimos" class="button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100" href="/cotizacion">Comprar</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 pt-5 pt-md-0">
          <div class="mx-0 mx-lg-3 back-color-white border-color-blue h-100 d-flex flex-column justify-content-between">
            <div class="back-color-bluelight">
              <div class="color-back-yellow">
                <img class="img-fluid icon-loca-yellow" src="@/assets/img/localizacion/icon-phone-yellow.svg" title="teléfonos" alt="icono de teléfonos" description="icono de teléfonos amarillo">
              </div>
            </div>
            <div class="h-100">
              <div class="pt-5 ">
                <h2 class="font-size-18 font-color-blue font-nuni-re text-left mb-4 px-4">Teléfonos</h2>
                <p class="font-size-14 font-koho-me text-left px-4"> <span class="d-block font-color-brown">Línea
                    1:</span>
                  <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" href="tel:5526088867" class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">55 26 08 88
                    67</a>

                  <span class="d-block font-color-brown">Línea 2:</span>
                  <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" href="tel:5554269941" class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">55 54 26 99
                    41</a><br><br>
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-around w-100 pb-lg-0">
              <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" class="button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100" href="tel:5526088867">Llamar
                por teléfono</a>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-4 pt-5 pt-md-0">
          <div class="mx-0 mx-lg-3 back-color-white border-color-blue h-100 d-flex flex-column justify-content-between">
            <div class="back-color-bluelight">
              <div class="color-back-yellow">
                <img class="img-fluid icon-loca-yellow" src="@/assets/img/localizacion/icon-person-yellow.svg" title="redes sociales" alt="icono de perfil" description="icono de perfil amarillo">
              </div>
            </div>
            <div class="h-100">
              <div class="pt-5">
                <h2 class="font-size-18 font-color-blue font-nuni-re text-left mb-4 px-4">Redes sociales</h2>
                <p class="font-size-14 font-koho-me text-left px-4"> <span
                    class="d-block font-color-brown">Facebook:</span>
                  <a title="Ir al facebook de creativos espacios" alt="Ir al perfil de facebook de creativos espacios" href="https://www.facebook.com/creativosespaciosmx"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">creativosespaciosmx</a>
                  <span class="d-block font-color-brown">Instagram:</span>
                  <a title="Ir al instagram de creativos espacios" alt="Ir al perfil de instagram de creativos espacios" href="https://www.instagram.com/creativosespaciosmx/"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">creativosespaciosmx</a> 
                     <span class="d-block font-color-brown">Youtube:</span>
                  <a title="Ir al youtube de creativos espacios" alt="Ir al perfil de youtube de creativos espacios" href="https://www.youtube.com/channel/UCEAQ-yq9sq0C-KCX8-aRyZA"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">@creativosespacios1108</a> 
                     <span class="d-block font-color-brown">Tik tok:</span>
                  <a title="Ir al tiktok de creativos espacios" alt="Ir al perfil de tiktok de creativos espacios" href="https://www.tiktok.com/@creativosespaciosmx"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">@creativosespaciosmx</a> 
                  <span class="d-block font-color-brown">Twitter:</span>
                  <a title="Ir al twitter de creativos espacios" alt="Ir al perfil de twitter de creativos espacios" href="https://twitter.com/creaespaciosmx"
                    class="ancor-style-brown font-color-brown p-1 mb-2 d-inline-block">@creaespaciosmx</a>
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-around w-100 pb-lg-0">
              <a title="Ir al facebook de creativos espacios" alt="Ir al perfil de facebook de creativos espacios" class="button-back-blue font-koho-me font-size-14 py-2 mt-3 d-block w-100" href="https://www.facebook.com/creativosespaciosmx">Visitar facebook</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatosLocalizacion',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>