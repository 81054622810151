<template>
  <div class="adecuaciones">
    <Header />
    <Nav />
      <TiposAdecuaciones/>
    <PasosAdecuaciones/>  
    <VideosAdecuaciones/>
    <ImagenesAdecuaciones/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import PasosAdecuaciones from '@/components/PasosAdecuaciones.vue'
import TiposAdecuaciones from '@/components/TiposAdecuaciones.vue'
import VideosAdecuaciones from '@/components/VideosAdecuaciones.vue'
import ImagenesAdecuaciones from '@/components/ImagenesAdecuaciones.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Adecuaciones',
  components: {
    Header,
    Nav,
    PasosAdecuaciones,
    TiposAdecuaciones,
    VideosAdecuaciones,
    ImagenesAdecuaciones,
    Footer
  }
}
</script>