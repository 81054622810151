<template>
  <div class="venta">
    <Header />
    <Nav/>    
    <div id="venta-de-contenedores" class="servicio-venta mt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="back-color-yellowlight position-relative">
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="row">
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0">
                    <img class="img-fluid image-servicios-zoom"
                      src="@/assets/img/inicio/venta-contenedores-maritimos.png" title="venta de contenedor marítimo" alt="imagen de contenedor marítimo nuevo" description="imagen de contenedor marítimo nuevo azul">
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-column align-items-start justify-content-center pr-lg-5">
                    <h2 class="font-size-24 font-color-brown font-nuni-bo text-left pt-3 pt-lg-0 px-3 pl-lg-0">Venta de contenedores<br class="d-none d-lg-block"> marítimos
                    </h2>
                    <p class="font-size-14 font-color-brown font-koho-me text-left px-3 pl-lg-0 mt-3">Tenemos contenedores marítimos
                      de
                      diversos tipos y tamaños como de 10 pies, 20 pies y 40 pies de
                      largo.</p>
                    <div class="d-flex justify-content-around w-100 pb-5 pb-lg-0">
                      <a title="Ir a cotizar" alt="Ir a la sección de cotización" class="button-back-blue font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/cotizacion">Quiero
                        comprar</a>
                      <a title="Ir a tipos de contenedores" alt="Ir a la sección de tipos" class="button-back-blue font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/tipos">Saber más</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <VideosAdecuaciones />
    <DatosLocalizacion/>
    <OficinaCentral/>
    <Contenedores/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import VideosAdecuaciones from '../components/VideosAdecuaciones.vue'
import DatosLocalizacion from '@/components/DatosLocalizacion.vue'
import OficinaCentral from '@/components/OficinaCentral.vue'
import Contenedores from '@/components/Contenedores.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Venta',
  components: {
    Header,
    Nav,    
    VideosAdecuaciones,
    DatosLocalizacion,
    OficinaCentral,
    Contenedores,
    Footer,
    
}
}
</script>
