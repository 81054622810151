<template>
  <div id="historia" class="historia">
    <div class="container-fluid section-banner-styles back-color-blue-black mt-5 mb-5 position-relative">      
      <div class="triangle-top-left "><img class="img-triangle-tl" src="@/assets/img/inicio/triangle-top-left.png"
          title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-top-right "><img class="img-triangle-tr" src="@/assets/img/nosotros/triangle-top-right.png"
          title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-bottom-left"><img class="img-triangle-bl" src="@/assets/img/inicio/triangle-bottom-left.png"
          title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-bottom-right "><img class="img-triangle-br"
          src="@/assets/img/nosotros/triangle-bottom-right.png" title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
          <div class="container">
 <div class="row py-5">
        <div class="col-12 col-md-6">
          <div class="position-relative mb-4">
            <h1 class="font-nuni-eb font-size-48 font-color-white line-heigt-1 position-relative text-left  z-index-2 mb-0">
              Nuestra historia</h1>
                <div class="container-rectangles-blue position-absolute z-index-1">                 
                <div class="back-rectangle-blue position-relative"></div>
                </div>
          </div>

          <div class="back-color-white mr-4">
            <p class="font-size-14 font-koho-me text-left p-4">
              Orgullosamente mexicanos, comenzamos operaciones en el 2010, con el objetivo de combatir el impacto
              ambiental y fomentar la reutilización de contenedores marítimos, cuando modificamos se ahorra energía y
              baja la producción de dioxido de carbono, en comparación con las construcciones comunes en donde se
              consumen más materiales y no se generan residuos no reutilizables.
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <img class="img-fluid w-50" src="@/assets/img/nosotros/logo-creativos-2010.svg" title="creativos espacios 2010" alt="logo creativos espacios 2010" description="logo creativos espacios 2010 colores">
        </div>
      </div>
          </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'Historia',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>