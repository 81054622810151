<template>
  <div class="patios mt-5 mb-5">
       <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="font-nuni-bo font-color-blueblack font-size-42 text-center py-3 px-0 px-sm-5">Contamos con patios en
          </h2>
        </div>
      </div>
       <div class="row">
        <div class="col-12 col-md-6 px-0 position-relative">
          <div class="title-site-yellow-1 position-absolute back-color-yellow px-4 py-1 w-75 mt-3"> <h3 class="font-nuni-bo font-size-36 font-color-brown mb-0 text-left">Manzanillo, Colima</h3></div>
         <img class="img-fluid w-100" src="@/assets/img/localizacion/contenedores-manzanillo.png" title="patio en Manzanillo" alt="imagen de patio de contenedores marítimos" description="imagen de patio de contenedores marítimos en Manzanillo">
        </div>
        <div class="col-12 col-md-6 px-0 position-relative">
          <div class="title-site-yellow-2 position-absolute back-color-yellow px-4 py-1 w-75 mb-3"> <h3 class="font-nuni-bo font-size-36 font-color-brown mb-0 text-left">Progreso, Yucatán</h3></div>
          <img class="img-fluid w-100" src="@/assets/img/localizacion/contenedores-progreso.png" title="patio en Progreso" alt="imagen de patio de contenedores marítimos" description="imagen de patio de contenedores marítimos en Progreso">
        </div>
         <div class="col-12 col-md-6 px-0 position-relative">
           <div class="title-site-yellow-3 position-absolute back-color-yellow px-4 py-1 w-75 mt-3"> <h3 class="font-nuni-bo font-size-36 font-color-brown mb-0 text-left">Altamira, Tamaulipas</h3></div>
         <img class="img-fluid w-100" src="@/assets/img/localizacion/contenedores-manzanillo.png" title="patio en Altamira" alt="imagen de patio de contenedores marítimos" description="imagen de patio de contenedores marítimos en Altamira">
        </div>
        <div class="col-12 col-md-6 px-0 position-relative">
          <div class="title-site-yellow-4 position-absolute back-color-yellow px-4 py-1 w-75 mb-3"> <h3 class="font-nuni-bo font-size-36 font-color-brown mb-0 text-left">Puerto, Veracruz</h3></div>
          <img class="img-fluid w-100" src="@/assets/img/localizacion/contenedores-puerto.png" title="patio en el Puerto de Veracruz" alt="imagen de patio de contenedores marítimos" description="imagen de patio de contenedores marítimos en el Puerto de Veracruz">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Patios',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>