<template>
  <div id="valores" class="valores">
   <div class="container-fluid back-color-bluebold py-5 position-relative">
     <div class="content-square-colors position-absolute">
       <img class="squares-deco-colors img-fluid" src="@/assets/img/nosotros/squares-deco-colors.png" title="arte de rectangulos" alt="imagen de arte de rectangulos" description="imagen de arte de rectangulos amarillos, azules y grises">
     </div>
     <div class="container font-color-white">
       <div class="row d-flex">
         <div class="col-12 col-md-4 d-flex flex-column justify-content-end align-items-center align-items-md-start order-1 order-md-0">
           <img class="img-fluid w-50" src="@/assets/img/nosotros/icon-heart-white.svg" title="corazón" alt="icono de corazón" description="icono de corazón blanco y rojo">
           <h3 class="font-nuni-re font-color-white font-size-36 text-left mt-4 mb-2">"Bienvenido"</h3>
           <p class="font-nuni-re font-color-white font-size-14 text-left mb-0">Queremos ofrecerte una experiencia</p>
         </div>
         <di class="col-12 col-md-8 order-0 order-md-1">
           <div class="d-flex flex-column flex-md-row justify-content-between w-100 pb-5">
             <div> <h2 class="font-nuni-bo font-color-white font-size-42 text-left">Valores</h2></div>
             <div> <h3 class="font-nuni-bo font-color-white font-size-42 text-right">| 2021</h3></div>
           </div>
              <div class="row">
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/nosotros/img-eye-yellow.png" title="Somos transparentes" alt="icono de ojo" description="icono de ojo amarillo">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18 mt-3 mt-lg-0">Somos transparentes</h3>
                    <p class="font-koho-me font-size-14 ">Tenemos la voluntad de comunicar claro, honesto y completo el proceso.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0">
                <div class="row">
                   <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/nosotros/img-diamond-green.png" title="Somos comprometidos" alt="icono de diamante" description="icono de diamante turquesa">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Somos comprometidos</h3>
                    <p class="font-koho-me font-size-14">Reconocemos nuestra responsabilidad social e involucramos a todos.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="row">
                   <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/nosotros/img-glass-white.png" title="Somos responsables" alt="icono de lentes" description="icono de lentes blancos">
                  </div>
                 <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Somos responsables</h3>
                    <p class="font-koho-me font-size-14">Tenemos virtudes y  valores de marca profundamente a los seres humanos.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/nosotros/img-wall-orange.png" title="Somos sostenibles" alt="icono de muro" description="icono de muro naranja">
                  </div>
                   <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Somos sostenibles</h3>
                    <p class="font-koho-me font-size-14">Satisfacemos las necesidades del presente sin comprometer la capacidad de las generaciones futuras.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/nosotros/img-message-blue.png" title="Somos difusivos" alt="icono de mensaje" description="icono de mensaje azul">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Somos difusivos</h3>
                    <p class="font-koho-me font-size-14">Promover la responsabilidad social, de los impactos ecologicos y su valor.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <div class="row">
                   <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/nosotros/img-person-red.png" title="Somos un ejemplo social" alt="icono de persona" description="icono de persona rojo">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Somos un ejemplo social</h3>
                    <p class="font-koho-me font-size-14">Tenemos la iniciativa y herramientas para demostrar que si se puede ser amigable con el medio ambiente</p>
                  </div>
                </div>
              </div>
            </div>
         </di>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'Valores',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>