<template>
  <div id="imagenes-contenedores" class="imagenesContenedores mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-10 col-md-6 col-lg-4 back-color-yellowlight">
          <h2 class="font-nuni-bo font-color-brown font-size-42 text-left mb-0 py-3 px-0 px-sm-5">Imágenes</h2>
        </div>
      </div>
    </div>
    <div class="container-fluid overflow-hidden">
      <div class="row d-flex justify-content-center back-color-yellowlight position-relative">
        <div class="squares-border-white position-absolute h-75 w-50 ">
          <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
          <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
          <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
        </div>
        <div class="w-100 d-block"></div>
        <div class="col-12 col-md-12 col-lg-10 col-xl-8">          
            <div class="py-5">
              <b-carousel class="py-5" id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#05AEF1"
                 img-height="400" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd">
                <!-- Text slides with image --> 
                 <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto"  height="400"
                      :src="require('@/assets/img/galeria/contenedor-1.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>

                <!-- Slides with custom text -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto"  height="400"
                      :src="require('@/assets/img/galeria/contenedor-2.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>

                <!-- Slides with image only -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto"  height="400"
                      :src="require('@/assets/img/galeria/contenedor-3.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto" height="400"
                      :src="require('@/assets/img/galeria/contenedor-4.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>               
              

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto" height="400"
                      :src="require('@/assets/img/galeria/contenedor-5.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>               
              

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto" height="400"
                      :src="require('@/assets/img/galeria/contenedor-6.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>               
              

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto" height="400"
                      :src="require('@/assets/img/galeria/contenedor-7.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>               
              

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto" height="400"
                      :src="require('@/assets/img/galeria/contenedor-8.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>               
              

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                  <template #img>
                    <img class="d-block img-fluid mx-auto" height="400"
                      :src="require('@/assets/img/galeria/contenedor-9.jpg')" title="foto de la galeria" alt="foto de la galeria de creativos espacios" description="foto de la galeria de contenedores creativos espacios">
                  </template>
                </b-carousel-slide>               
                            
              </b-carousel>

              
            </div>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImagenesContenedores',
    props: {
      msg: String
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>