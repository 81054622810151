<template>
  <div class="tipos">
    <Header />
    <Nav />
    <Contenedores/>
    <VideosContenedores/>
    <ImagenesContenedores/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Contenedores from '@/components/Contenedores.vue'
import VideosContenedores from '@/components/VideosContenedores.vue'
import ImagenesContenedores from '@/components/ImagenesContenedores.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Tipos',
  components: {
    Header,
    Nav,
    Contenedores,
    VideosContenedores,
    ImagenesContenedores,
    Footer
  }
}
</script>