<template>
  <div id="clientes" class="clientes mt-5 pb-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-nuni-bo font-color-blueblack font-size-42 text-center py-3 px-0 px-sm-5">Clientes
          </h1>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 position-relative">
          <div class="border-color-brown w-100 h-75 position-absolute position-bottom-0 z-index-1 d-none d-md-block">
            </div>
            <div class="row d-flex justify-content-around align-items-center z-index-2 position-relative pb-3 font-color-brown font-nuni-bo font-size-18">
              <div class="col-12 col-md-4 pt-5 pt-md-0">
                <img class="img-fluid" src="@/assets/img/nosotros/cliente-1.jpg" title="Cliente SixFlags" alt="imagen de SixFlags" description="imagen de SixFlags México"> <br>
                <span class="">SixFlags</span>
                </div>
              <div class="col-12 col-md-4 pt-5 pt-md-0">
                <img class="img-fluid" src="@/assets/img/nosotros/cliente-2.png" title="Cliente Uquifa" alt="imagen de Uquifa" description="imagen de Uquifa colores"> <br>
                <span class="">Uquifa</span>
              </div>
              <div class="col-12 col-md-4 pt-5 pt-md-0">
<img class="img-fluid" src="@/assets/img/nosotros/cliente-3.png" title="Cliente Holcim" alt="imagen de Holcim" description="imagen de Holcim nuevo"><br>
 <span class="">Holcim</span>
              </div>
              
              
            </div>
            <div class="d-flex justify-content-around align-items-center pb-5 ">
             
              
             
            </div> 
            <div class="d-flex justify-content-center font-color-brown font-nuni-bo font-size-14 pb-4">
              <p>Nuestros clientes expiden cartas de recomendación de nosotros, solicita una al contáctarnos.</p></div>         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Clientes',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>