<template>
  <div class="renta">
    <Header />
    <Nav/>    
    <div id="renta-de-contenedores" class="servicio-renta mt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="back-color-metalblack position-relative">
                <div class="square-decoration-white back-color-white position-absolute d-none d-lg-block"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="row">
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0 d-flex flex-column align-items-start justify-content-center pl-lg-5">


                    <h2 class="font-size-24 font-color-white font-nuni-bo text-left  pl-3 pl-lg-0">Renta de contenedores<br class="d-none d-lg-block"> marítimos
                    </h2>
                    <p class="font-size-14 font-color-white font-koho-me text-left mt-3 pl-3 pl-lg-0">Contamos con la renta de contenedores marítimos listos para ser ocupados, contáctanos para conocer la disponiblidad y documentación necesaria para obtener el tuyo.</p>
                    <div class="d-flex justify-content-around w-100 ">
                      <a title="Ir a cotizar" alt="Ir a la sección de cotización" class="button-back-yellow font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/cotizacion">Quiero
                        rentar</a>
                        <a title="Ir a tipos de contenedores" alt="Ir a la sección de tipos" class="button-back-yellow font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/tipos">Saber más</a>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0 pb-5 pb-lg-0">
                    <img class="img-fluid image-servicios-zoom"
                      src="@/assets/img/inicio/renta-contenedores-maritimos.png" title="renta de contenedor marítimo" alt="imagen de contenedor marítimo usado" description="imagen de contenedor marítimo usado verde">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <VideosAdecuaciones />
    <DatosLocalizacion/>
    <OficinaCentral/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import VideosAdecuaciones from '../components/VideosAdecuaciones.vue'
import DatosLocalizacion from '@/components/DatosLocalizacion.vue'
import OficinaCentral from '@/components/OficinaCentral.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Renta',
  components: {
    Header,
    Nav,
    VideosAdecuaciones,
    DatosLocalizacion,
    OficinaCentral,
    Footer,
    
}
}
</script>
